<template>
  <div class="job-container">
    <template v-if="childDataLoaded">
      <div class="page" v-for="(field, name, index) in page.fields" :key="index">
        <div class="page-container wysiwyg" v-html="field" v-if="name ==='wysiwyg'"></div>
      </div>
      <div v-if="page.name" class="submit-container">
        <btn custom-class="button-link"
          :text="`Apply for ${page.name}`"
          :href="jobMailTo">
        </btn>
      </div>
    </template>
  </div>
</template>

<script>

import Btn from '@/components/basic/Btn.vue';
import {
  butter
} from '@/buttercms';

export default {
  name: 'Page',
  components: {
    Btn,
  },
  data() {
    return {
      title: 'Page',
      slug: this.$route.params.slug,
      childDataLoaded: false,
      page: {
        slug: '',
        fields: {},
      },
    };
  },
  computed: {
    jobMailTo() {
      return `mailto:jobs@healthytogether.io?subject=${this.page.name}`;
    },
  },
  methods: {
    fetchPage() {
      const locale = this.$route.params.locale;
      butter.page.retrieve('jobs', this.slug, { locale: locale })
        .then((res) => {
          this.page = res.data.data;
          this.childDataLoaded = true;
        })
        .catch((e) => {
          console.warn(e);
          this.$router.push({ name: 'jobs' });
        });
    },
  },
  created() {
    this.fetchPage();
  },
};
</script>

<style scoped lang="scss">
.job-container {
  margin-bottom: 4rem;
}
.submit-container {
  @include bp-xsmall {
    margin: 2rem 0 0;
  }
  @include bp-small {
    margin: 3rem 0 0;
  }
  @include bp-medium {
    margin: 4rem 0 0;
  }
  @include bp-large {
    margin: 5rem 0 0;
  }
}
</style>
